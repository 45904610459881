/* Profile.css */
body, html {
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Arial, sans-serif;
    background-color: #f0f2f5;
  }
  
  .container {
    text-align: center;
    background: #fff;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 320px;
    margin: 20px auto;
    height: 560px;
  }
  
  .profile-picture {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin-bottom: 10px;
  }
  
  form input[type="text"] {
    padding: 10px;
    margin: 10px 0;
    width: calc(100% - 22px);
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  form button {
    padding: 10px 20px;
    background-color: #36d7b7;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
  }
  
  form button:hover {
    background-color: #2bb198;
  }
  
  .container h3 {
    margin-top: 20px;
  }
  
  button {
    padding: 10px 20px;
    background-color: #36d7b7;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #2bb198;
  }
  