/* global.css */
body, html {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Arial, sans-serif;
  background-color: #f0f2f5;
}


a {
  color: #E63946;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

nav {
  background-color: #E63946;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 320px;
  margin: 20px auto;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

nav ul li {
  margin: 0 15px;
}

nav ul li a {
  color: #F1FAEE;
  font-weight: bold;
}

h2, h3 {
  color: #E63946;
}

button {
  background-color: #1D3557;
  border: none;
  color: #F1FAEE;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
}

button:hover {
  background-color: #457B9D;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #F1FAEE;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.profile-picture, .photo {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  margin-top: 10px;
}

header {
  top: 10px;
}

.voting-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.profile-card {
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s;
}

.profile-card:hover {
  transform: scale(1.05);
}
