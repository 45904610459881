/* Voting.css */
body, html {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Arial, sans-serif;
  background-color: #f0f2f5;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.voting-container {
  text-align: center;
  background: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 320px;
  margin: 20px auto;
  display: block;
  height: 560px;
}

.profiles {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.profile {
  cursor: pointer;
  text-align: center;
  margin: 5px 0;
}

.profile-picture {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  object-fit: cover;
}

.profile-username {
  font-weight: bold;
}
