/* Rankings.css */
body, html {
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Arial, sans-serif;
    background-color: #f0f2f5;
  }
  
  .rankings-container {
    text-align: center;
    background: #fff;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 320px;
    margin: 20px auto;
    height: 560px;
  }
  
  .rankings-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .ranking-item {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }
  
  .rank-number {
    font-weight: bold;
    margin-right: 10px;
  }
  
  .ranking-profile-picture {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 10px;
  }
  
  .ranking-username {
    flex-grow: 1;
    font-weight: bold;
  }
  
  .ranking-score {
    margin-left: 10px;
  }
  